import { Model } from "./schema";

/**
 * Generates a random integer from a (inclusive) to b (exclusive).
 */
function randInt(a: number, b: number): number {
  a = Math.ceil(a);
  b = Math.floor(b);
  return Math.floor(Math.random() * (b - a) + a);
}

/**
 * Returns the next state of the Markov chain.
 */
function nextState(model: Model, state: string[]): string {
  const key = state.join(" ");
  const info = model.states[key];

  if (info == null) {
    return "#";
  }

  let guess = randInt(1, info.total + 1);
  for (const transition of info.next) {
    guess -= transition.weight;
    if (guess <= 0) {
      return transition.state;
    }
  }

  // This should be unreachable.
  return "#";
}

/**
 * Generates a word using a Markov chain.
 */
function generateWord(model: Model): string {
  const word = [];

  // Initialize state.
  const state = [];
  for (let i = 0; i < model.memory; i++) {
    state.push("#");
  }

  for (;;) {
    const next = nextState(model, state);
    if (next === "#") {
      return word.join("");
    }
    state.shift();
    state.push(next);
    word.push(next);
  }
}

/**
 * Generates a number of words using a Markov chain.
 */
export function* generateWords(model: Model, count: number): Iterable<string> {
  for (let i = 0; i < count; i++) {
    yield generateWord(model);
  }
}
