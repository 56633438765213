import "./index.css";
import { generateWords } from "./markov";
import { fetchModel } from "./requests";
import { Model } from "./schema";

/**
 * Creates a paragraph of gibberish.
 */
function createParagraph(model: Model): HTMLParagraphElement {
  const p = document.createElement("p");
  const words = Array.from(generateWords(model, 30));
  p.textContent = words.join(" ");
  return p;
}

function main() {
  let model: Model | null = null;

  const div = document.getElementById("output") as HTMLDivElement;
  const select = document.getElementById("dialect") as HTMLSelectElement;

  const button = document.getElementById("speak") as HTMLButtonElement;
  button.addEventListener("click", async () => {
    if (model?.language !== select.value) {
      model = await fetchModel(select.value);
    }

    const p = createParagraph(model);
    const blockquote = document.createElement("blockquote");
    blockquote.appendChild(p);

    div.replaceChildren(blockquote);
  });
}

main();
