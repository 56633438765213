import { Model } from "./schema";

/**
 * Fetches a Markov chain from the server.
 */
export async function fetchModel(language: string): Promise<Model> {
  const url = `/models/${language}-3.json`;
  const response = await fetch(url);
  return response.json();
}
